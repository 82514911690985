<template>
  <b-modal
    ref="edit-locale-modal"
    size="xl"
    @hide="$emit('locale-edit-cancel')"
    scrollable
    centered
  >
    <template #modal-header>
      <h5>{{ modalTitle }}</h5>
    </template>
    <template #default>
      <div class="form-group">
        <label>Código de Idioma</label>
        <input
          type="text"
          class="form-control form-control-solid form-control-lg"
          name="name"
          placeholder="Ejemplo: ES"
          v-model="translationData.localeCode"
          maxlength="2"
        />
        <div
          class="error text-primary"
          v-if="!$v.translationData.localeCode.unique"
        >
          El código {{ translationData.localeCode }} ya ha sido usado.
        </div>
        <div
          class="error text-primary"
          v-if="!$v.translationData.localeCode.required"
        >
          Este campo es obligatorio
        </div>
        <div
          class="error text-primary"
          v-if="!$v.translationData.localeCode.minLength"
        >
          Son requeridos por lo menos 2 caracteres.
        </div>
      </div>
      <div class="form-group">
        <label>Nombre</label>
        <input
          type="text"
          class="form-control form-control-solid form-control-lg"
          name="name"
          placeholder="Nombre del servicio"
          v-model="translationData.name"
        />
        <span class="form-text text-muted"
          >Se mostrará en el marketplace de los clientes</span
        >
        <div
          class="error text-primary"
          v-if="!$v.translationData.name.required"
        >
          Este campo es obligatorio
        </div>
        <div
          class="error text-primary"
          v-if="!$v.translationData.name.minLength"
        >
          Son requeridos por lo menos 5 caracteres.
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12">
          <div class="form-group">
            <label>Descripción</label>
            <textarea
              type="text"
              class="form-control form-control-solid form-control-lg"
              name="description"
              placeholder="Descripción del servicio"
              v-model="translationData.description"
            ></textarea>
            <span class="form-text text-muted"
              >Agrega una descripción para el servicio</span
            >
            <div
              class="error text-primary"
              v-if="!$v.translationData.description.required"
            >
              Este campo es obligatorio
            </div>
            <div
              class="error text-primary"
              v-if="!$v.translationData.description.minLength"
            >
              Son requeridos por lo menos 25 caracteres.
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label>Industrias</label>
        <b-form-tags
          class="form-control form-control-solid form-control-lg"
          placeholder="Agrega una industria y presiona enter"
          input-id="translation-industries-basic"
          v-model="translationData.industries"
        ></b-form-tags>
      </div>
      <div class="form-group">
        <label>Etiquetas</label>
        <b-form-tags
          class="form-control form-control-solid form-control-lg"
          placeholder="Agrega una etiqueta y presiona enter"
          input-id="translation-tags-basic"
          v-model="translationData.tags"
        ></b-form-tags>
        <div
          class="error text-primary"
          v-if="!$v.translationData.tags.required"
        >
          Este campo es obligatorio
        </div>
        <div
          class="error text-primary"
          v-if="!$v.translationData.tags.minLength"
        >
          Son requeridas por lo menos 2 etiquetas.
        </div>
      </div>
      <div class="form-group" ref="documentation" id="documentation">
        <p>Escribe las instrucciones para utilizar el demo del servicio</p>
        <textarea
          class="form-control form-control-solid form-control-lg"
          v-model="translationData.instructions"
        ></textarea>
        <div
          class="error text-primary"
          v-if="!$v.translationData.instructions.minLength"
        >
          Son requeridos por lo menos 25 caracteres.
        </div>
        <div
          class="error text-primary"
          v-if="!$v.translationData.instructions.required"
        >
          Este campo es obligatorio
        </div>
      </div>

      <h4 class="mb-10 font-weight-bold text-dark">Documentación de uso</h4>
      <div class="form-group">
        <ckeditor
          v-model="translationData.documentation"
          :config="$options.editorConfig"
          @ready="onEditorReady"
        ></ckeditor>
      </div>
    </template>
    <template #modal-footer>
      <!-- Emulate built in modal footer ok and cancel button actions -->
      <b-button
        size="md"
        @click="$emit('locale-edit-accept')"
        :disabled="$v.translationData.$invalid"
      >
        <!-- <b-button size="md" @click="$emit('locale-edit-accept', translationData)"> -->
        <!-- <b-button size="md" @click="$emit('locale-edit-accept', Object.assign({},translationData))"> -->
        Aceptar
      </b-button>
      <b-button size="md" @click="$emit('locale-edit-cancel')">
        Cancelar
      </b-button>
    </template>
  </b-modal>
</template>
<script>
import { required, minLength } from "vuelidate/lib/validators";
import ApiService from "@/core/services/api.service";

let editorInstance;

const RESOURCE = "ai-products";
const cv = {
  service: {
    async nameNotExists(value) {
      if (!value) return true;
      var resource = await ApiService.query(RESOURCE, {
        filter: {
          limit: 1,
          where: {
            and: [{ name: value }],
          },
        },
      });
      let id = window.location.hash.split("/")?.pop();
      // console.log("id", id);
      return resource.data.length == 0 || resource.data.pop().id == id;
    },
    checkUniqueLocaleCode(value) {
      let found = this.usedLocaleCodes.find((e) => e == value);
      if (found !== undefined && value != this.originalLocaleCode) {
        return false;
      }
      return true;
    },
  },
};

export default {
  name: "EditServiceLocale",
  props: {
    translationData: Object,
    show: Boolean,
    usedLocaleCodes: Array,
  },
  editorConfig: {
    extraPlugins: "font",
    font_names:
      "Arial;Arial Black;Courier New;Times New Roman;Poppins/Poppins,sans-serif;Roboto/Roboto,sans-serif;Verdana;",
    toolbar: [
      {
        name: "document",
        items: [
          "Source",
          "-",
          "Save",
          "NewPage",
          "ExportPdf",
          "Preview",
          "Print",
        ],
      },
      {
        name: "clipboard",
        items: [
          "Cut",
          "Copy",
          "Paste",
          "PasteText",
          "PasteFromWord",
          "-",
          "Undo",
          "Redo",
        ],
      },
      {
        name: "editing",
        items: ["Find", "Replace", "-", "SelectAll", "-", "Scayt"],
      },
      {
        name: "basicstyles",
        items: [
          "Bold",
          "Italic",
          "Underline",
          "Strike",
          "Subscript",
          "Superscript",
          "-",
          "CopyFormatting",
          "RemoveFormat",
        ],
      },
      {
        name: "paragraph",
        items: [
          "NumberedList",
          "BulletedList",
          "-",
          "Outdent",
          "Indent",
          "-",
          "Blockquote",
          "CreateDiv",
          "-",
          "JustifyLeft",
          "JustifyCenter",
          "JustifyRight",
          "JustifyBlock",
        ],
      },
      { name: "links", items: ["Link", "Unlink", "Anchor"] },
      {
        name: "insert",
        items: [
          "Table",
          "HorizontalRule",
          "Smiley",
          "SpecialChar",
          "PageBreak",
        ],
      },
      "/",
      { name: "styles", items: ["Styles", "Format", "Font", "FontSize"] },
      { name: "colors", items: ["TextColor", "BGColor"] },
      { name: "tools", items: ["Maximize", "ShowBlocks"] },
    ],
  },
  data() {
    return {
      originalLocaleCode: "",
    };
  },
  methods: {
    showModal() {
      this.$refs["edit-locale-modal"].show();
    },
    hideModal() {
      editorInstance.destroy();
      this.$refs["edit-locale-modal"].hide();
    },
    onEditorReady(editor) {
      editorInstance = editor;
    },
  },
  watch: {
    show: function () {
      if (this.show) {
        if (this.translationData.localeCode) {
          this.originalLocaleCode = new String(this.translationData.localeCode);
        }
        this.showModal();
      } else {
        this.hideModal();
      }
    },
    "translationData.localeCode": function () {
      if (this.translationData.localeCode) {
        this.translationData.localeCode = this.translationData.localeCode.toUpperCase();
      }
    },
  },
  computed: {
    modalTitle() {
      //   console.log(this.translationData);
      if (this.translationData.name == "") {
        return "Nueva Traducción " + this.translationData.localeCode;
      } else {
        return "Editar Traducción " + this.translationData.localeCode;
      }
    },
  },
  validations: {
    translationData: {
      localeCode: {
        required,
        minLength: minLength(2),
        unique: cv.service.checkUniqueLocaleCode,
      },
      name: {
        required,
        minLength: minLength(5),
        notExists: cv.service.nameNotExists,
      },
      description: {
        required,
        minLength: minLength(25),
      },
      tags: {
        required,
        minLength: minLength(2),
      },
      instructions: {
        required,
        minLength: minLength(25),
      },
    },
  },
};
</script>