var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-modal", {
    ref: "edit-locale-modal",
    attrs: { size: "xl", scrollable: "", centered: "" },
    on: {
      hide: function($event) {
        return _vm.$emit("locale-edit-cancel")
      }
    },
    scopedSlots: _vm._u([
      {
        key: "modal-header",
        fn: function() {
          return [_c("h5", [_vm._v(_vm._s(_vm.modalTitle))])]
        },
        proxy: true
      },
      {
        key: "default",
        fn: function() {
          return [
            _c("div", { staticClass: "form-group" }, [
              _c("label", [_vm._v("Código de Idioma")]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.translationData.localeCode,
                    expression: "translationData.localeCode"
                  }
                ],
                staticClass: "form-control form-control-solid form-control-lg",
                attrs: {
                  type: "text",
                  name: "name",
                  placeholder: "Ejemplo: ES",
                  maxlength: "2"
                },
                domProps: { value: _vm.translationData.localeCode },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.translationData,
                      "localeCode",
                      $event.target.value
                    )
                  }
                }
              }),
              !_vm.$v.translationData.localeCode.unique
                ? _c("div", { staticClass: "error text-primary" }, [
                    _vm._v(
                      " El código " +
                        _vm._s(_vm.translationData.localeCode) +
                        " ya ha sido usado. "
                    )
                  ])
                : _vm._e(),
              !_vm.$v.translationData.localeCode.required
                ? _c("div", { staticClass: "error text-primary" }, [
                    _vm._v(" Este campo es obligatorio ")
                  ])
                : _vm._e(),
              !_vm.$v.translationData.localeCode.minLength
                ? _c("div", { staticClass: "error text-primary" }, [
                    _vm._v(" Son requeridos por lo menos 2 caracteres. ")
                  ])
                : _vm._e()
            ]),
            _c("div", { staticClass: "form-group" }, [
              _c("label", [_vm._v("Nombre")]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.translationData.name,
                    expression: "translationData.name"
                  }
                ],
                staticClass: "form-control form-control-solid form-control-lg",
                attrs: {
                  type: "text",
                  name: "name",
                  placeholder: "Nombre del servicio"
                },
                domProps: { value: _vm.translationData.name },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.translationData, "name", $event.target.value)
                  }
                }
              }),
              _c("span", { staticClass: "form-text text-muted" }, [
                _vm._v("Se mostrará en el marketplace de los clientes")
              ]),
              !_vm.$v.translationData.name.required
                ? _c("div", { staticClass: "error text-primary" }, [
                    _vm._v(" Este campo es obligatorio ")
                  ])
                : _vm._e(),
              !_vm.$v.translationData.name.minLength
                ? _c("div", { staticClass: "error text-primary" }, [
                    _vm._v(" Son requeridos por lo menos 5 caracteres. ")
                  ])
                : _vm._e()
            ]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-xl-12" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c("label", [_vm._v("Descripción")]),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.translationData.description,
                        expression: "translationData.description"
                      }
                    ],
                    staticClass:
                      "form-control form-control-solid form-control-lg",
                    attrs: {
                      type: "text",
                      name: "description",
                      placeholder: "Descripción del servicio"
                    },
                    domProps: { value: _vm.translationData.description },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.translationData,
                          "description",
                          $event.target.value
                        )
                      }
                    }
                  }),
                  _c("span", { staticClass: "form-text text-muted" }, [
                    _vm._v("Agrega una descripción para el servicio")
                  ]),
                  !_vm.$v.translationData.description.required
                    ? _c("div", { staticClass: "error text-primary" }, [
                        _vm._v(" Este campo es obligatorio ")
                      ])
                    : _vm._e(),
                  !_vm.$v.translationData.description.minLength
                    ? _c("div", { staticClass: "error text-primary" }, [
                        _vm._v(" Son requeridos por lo menos 25 caracteres. ")
                      ])
                    : _vm._e()
                ])
              ])
            ]),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", [_vm._v("Industrias")]),
                _c("b-form-tags", {
                  staticClass:
                    "form-control form-control-solid form-control-lg",
                  attrs: {
                    placeholder: "Agrega una industria y presiona enter",
                    "input-id": "translation-industries-basic"
                  },
                  model: {
                    value: _vm.translationData.industries,
                    callback: function($$v) {
                      _vm.$set(_vm.translationData, "industries", $$v)
                    },
                    expression: "translationData.industries"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", [_vm._v("Etiquetas")]),
                _c("b-form-tags", {
                  staticClass:
                    "form-control form-control-solid form-control-lg",
                  attrs: {
                    placeholder: "Agrega una etiqueta y presiona enter",
                    "input-id": "translation-tags-basic"
                  },
                  model: {
                    value: _vm.translationData.tags,
                    callback: function($$v) {
                      _vm.$set(_vm.translationData, "tags", $$v)
                    },
                    expression: "translationData.tags"
                  }
                }),
                !_vm.$v.translationData.tags.required
                  ? _c("div", { staticClass: "error text-primary" }, [
                      _vm._v(" Este campo es obligatorio ")
                    ])
                  : _vm._e(),
                !_vm.$v.translationData.tags.minLength
                  ? _c("div", { staticClass: "error text-primary" }, [
                      _vm._v(" Son requeridas por lo menos 2 etiquetas. ")
                    ])
                  : _vm._e()
              ],
              1
            ),
            _c(
              "div",
              {
                ref: "documentation",
                staticClass: "form-group",
                attrs: { id: "documentation" }
              },
              [
                _c("p", [
                  _vm._v(
                    "Escribe las instrucciones para utilizar el demo del servicio"
                  )
                ]),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.translationData.instructions,
                      expression: "translationData.instructions"
                    }
                  ],
                  staticClass:
                    "form-control form-control-solid form-control-lg",
                  domProps: { value: _vm.translationData.instructions },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.translationData,
                        "instructions",
                        $event.target.value
                      )
                    }
                  }
                }),
                !_vm.$v.translationData.instructions.minLength
                  ? _c("div", { staticClass: "error text-primary" }, [
                      _vm._v(" Son requeridos por lo menos 25 caracteres. ")
                    ])
                  : _vm._e(),
                !_vm.$v.translationData.instructions.required
                  ? _c("div", { staticClass: "error text-primary" }, [
                      _vm._v(" Este campo es obligatorio ")
                    ])
                  : _vm._e()
              ]
            ),
            _c("h4", { staticClass: "mb-10 font-weight-bold text-dark" }, [
              _vm._v("Documentación de uso")
            ]),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("ckeditor", {
                  attrs: { config: _vm.$options.editorConfig },
                  on: { ready: _vm.onEditorReady },
                  model: {
                    value: _vm.translationData.documentation,
                    callback: function($$v) {
                      _vm.$set(_vm.translationData, "documentation", $$v)
                    },
                    expression: "translationData.documentation"
                  }
                })
              ],
              1
            )
          ]
        },
        proxy: true
      },
      {
        key: "modal-footer",
        fn: function() {
          return [
            _c(
              "b-button",
              {
                attrs: {
                  size: "md",
                  disabled: _vm.$v.translationData.$invalid
                },
                on: {
                  click: function($event) {
                    return _vm.$emit("locale-edit-accept")
                  }
                }
              },
              [_vm._v(" Aceptar ")]
            ),
            _c(
              "b-button",
              {
                attrs: { size: "md" },
                on: {
                  click: function($event) {
                    return _vm.$emit("locale-edit-cancel")
                  }
                }
              },
              [_vm._v(" Cancelar ")]
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }