<style lang="scss" scoped>
</style>

<template>
  <div class="form-group">
    <label>{{ label }}</label>
    <b-row class="my-1" v-for="(item, index) in dataArray" :key="index">
      <b-col>
        <b-form-input
          v-model="item.key"
          type="text"
          :state="isValidKey(index)"
          :formatter="formatter"
          @update="onRowChange(index)"
        ></b-form-input>
      </b-col>
      <b-col>
        <b-form-input
          v-model="item.value"
          type="text"
          :state="isValidValue(index)"
          :formatter="formatter"
          @update="onRowChange(index)"
        ></b-form-input>
      </b-col>
      <b-col cols="1">
        <b-button
          type="button"
          variant="outline-primary"
          v-show="item.value != '' || item.key != ''"
          @click="removeRow(index)"
          >×
        </b-button>
      </b-col>
    </b-row>
    <span v-if="helpText != null" class="form-text text-muted">{{
      helpText
    }}</span>
  </div>
</template>

<script>
export default {
  name: "HeaderList",
  props: {
    dataObject: {
      type: Object,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    helpText: {
      type: String,
      default: null,
    }
  },
  data() {
    return {
      dataArray: [{ key: "", value: "" }]
    };
  },
  mounted() {
  },
  methods: {
    formatter(val) {
      return val.replace(/[^\w\d\s-/()<>@,.;:\\{}?[\]=]/g, '');
    },
    onRowChange(index) {
      if (
        !this.dataArray.some(
          (element) => element.key == "" && element.value == ""
        )
      ) {
        if (
          this.dataArray[index].key != "" ||
          this.dataArray[index].value != ""
        ) {
          this.dataArray.push({ key: "", value: "" });
        }
      } else if (index == this.dataArray.length - 2) {
        if (
          this.dataArray[index].key == "" &&
          this.dataArray[index].value == ""
        ) {
          this.dataArray.splice(index + 1, 1);
        }
      }
    },
    removeRow(index) {
        this.dataArray.splice(index, 1);
    },
    isValidKey(index) {
      let valid = null;
      if (this.dataArray[index].key == "") {
        valid = this.dataArray[index].value == "" ? null : false;
      }
      else {
        valid = this.dataArray.some((element, i)=> i != index && element.key == this.dataArray[index].key ) ? false : true;
      }
      return valid;

    },
    isValidValue(index) {
      let valid = null;
      if (this.dataArray[index].value == "") {
        valid = this.dataArray[index].key == "" ? null : false;
      }
      else {
        valid  = true;
      }
      return valid;
    },
    isValid() {
      let valid = true;
      for (let i = 0; i < this.dataArray.length; i++) {
        if (this.isValidKey(i) === false || this.isValidValue(i) === false) {
          valid = false;
          break;
        }
      }
      return valid;
    },
    getHeaderObject() {
      let headers = {};
      try {
        this.dataArray.forEach(element => {
          if(element.key != "" && element.value != "")
          {
            headers[element.key] = element.value;
          }
        });
      } catch (error) {
        return error;
      }
      return headers;
    }
  },
  computed: {
    valid() {
      return this.isValid();
    }
  },
  watch: {
    "dataObject": {
      handler: function () {
        for (let key in this.dataObject) {
          if (Object.hasOwnProperty.call(this.dataObject, key)) {
            this.dataArray.splice(0, 0, { key: key, value: this.dataObject[key] });
          }
        }
      }
    }
  }
};
</script>