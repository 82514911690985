var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-group" },
    [
      _c("label", [_vm._v(_vm._s(_vm.label))]),
      _vm._l(_vm.dataArray, function(item, index) {
        return _c(
          "b-row",
          { key: index, staticClass: "my-1" },
          [
            _c(
              "b-col",
              [
                _c("b-form-input", {
                  attrs: {
                    type: "text",
                    state: _vm.isValidKey(index),
                    formatter: _vm.formatter
                  },
                  on: {
                    update: function($event) {
                      return _vm.onRowChange(index)
                    }
                  },
                  model: {
                    value: item.key,
                    callback: function($$v) {
                      _vm.$set(item, "key", $$v)
                    },
                    expression: "item.key"
                  }
                })
              ],
              1
            ),
            _c(
              "b-col",
              [
                _c("b-form-input", {
                  attrs: {
                    type: "text",
                    state: _vm.isValidValue(index),
                    formatter: _vm.formatter
                  },
                  on: {
                    update: function($event) {
                      return _vm.onRowChange(index)
                    }
                  },
                  model: {
                    value: item.value,
                    callback: function($$v) {
                      _vm.$set(item, "value", $$v)
                    },
                    expression: "item.value"
                  }
                })
              ],
              1
            ),
            _c(
              "b-col",
              { attrs: { cols: "1" } },
              [
                _c(
                  "b-button",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: item.value != "" || item.key != "",
                        expression: "item.value != '' || item.key != ''"
                      }
                    ],
                    attrs: { type: "button", variant: "outline-primary" },
                    on: {
                      click: function($event) {
                        return _vm.removeRow(index)
                      }
                    }
                  },
                  [_vm._v("× ")]
                )
              ],
              1
            )
          ],
          1
        )
      }),
      _vm.helpText != null
        ? _c("span", { staticClass: "form-text text-muted" }, [
            _vm._v(_vm._s(_vm.helpText))
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }